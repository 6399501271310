import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Footer from "../components/footer";
import { Link } from "gatsby"
import { Helmet } from 'react-helmet';


import '../styles/homeStyles2.scss'
import '../css/icofont.min.css'
import '../css/animate.min.css'
/* import blueWing from '../assets/img/blue-wing.svg'
import greenWing from '../assets/img/green-wing.svg'
import { motion } from "framer-motion"; */


const IndexPage = props => {
  const initState = {
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
    isOpen: false
};

  const [state, setState] = useState(initState)

  const openModal = () => {
    setState({isOpen: true})
}

  const makeTimer = () => {
    let endTime = new Date("October 23, 2020 09:00:00 PDT");			
    let endTimeParse = (Date.parse(endTime)) / 1000;
    let now = new Date();
    let nowParse = (Date.parse(now) / 1000);
    let timeLeft = endTimeParse - nowParse;
    let days = Math.floor(timeLeft / 86400); 
    let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
    let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
    let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
    if (hours < "10") { hours = "0" + hours; }
    if (minutes < "10") { minutes = "0" + minutes; }
    if (seconds < "10") { seconds = "0" + seconds; }
    setState({
        days, hours, minutes, seconds
    });
}

useEffect(() => {
 const myInterval = setInterval(() => { 
    makeTimer();
}, 1000);
  return () => {
    clearInterval(myInterval);
  }
}, [])

  return <Layout>
             <Helmet>
                <title>Next.Legal | Homepage</title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://yourapp.com" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal | Homepage" />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://next-legal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal | Homepage" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
             </Helmet>
            

             <div className="main-banner">

                <div className="container">
                  <div className="summit_copy">
                      <h2>Next.Legal Summit 2020</h2>
                      <p>An Integrated ecosystem built on radical ideas</p>
                      <p>We invite you to join us for keynotes, launches, sessions, and more 23rd October</p>
                      <div className="button-box">
                              <Link to="#" className="btn btn-primary">Register</Link>
                                        <Link 
                                            onClick={e => {e.preventDefault(); this.openModal()}}
                                            to="#" 
                                            className="video-btn popup-youtube"
                                        >
                                            <i className="icofont-ui-play"></i> Watch Pormo Video
                                        </Link>
                       </div>
                  </div>
                </div>

              
                    <div className="event-countdown">
                        <div id="timer">
                            <div id="days">{state.days} <span>Days</span></div>
                            <div id="hours">{state.hours} <span>Hours</span></div>
                            <div id="minutes">{state.minutes} <span>Minutes</span></div>
                            <div id="seconds">{state.seconds} <span>Seconds</span></div>
                        </div>
                    </div>


            </div>
        </Layout>
}

export default IndexPage
