import React from 'react';
import '../styles/footerStyles.scss';
import 'knopf.css';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';


const footer = () => {
    return (
        <div className="footr">
        <motion.div 
         animate={{y:0 , opacity: 1}}
         initial={{ y: 72, opacity: 0 }}
         transition={{
           duration: 1,
           ease: [0.6, 0.05, -0.01, 0.9],
           delay: 0.4
         }}
        className="footer-container">
            <Link
            className="linked"
            to="/privacy"
            >
                <h3>privacy policy</h3>
            </Link>    
            <div className="register-card">
                <h2>Join us at the 2nd Next.legal Summit on October 23rd 2020</h2>
                <Link
                to="/register"
                >
                <div><button className="knopf wide pill events register" >REGISTER NOW</button></div>
                </Link>
            </div>
            <div className="socials">
                <span className="social">FB.</span>
                <span className="social">TW.</span>
                <span className="social">LI.</span>
            </div>
        </motion.div>
    </div>
    )
}

export default footer
